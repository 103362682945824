export default class DateUtilService{
    static getLocalDateFromUtcMilli(milli:number){
        if(milli){
            let date = new Date(0);
            date.setMilliseconds(milli);
            const dateOptions:Intl.DateTimeFormatOptions = { year: '2-digit', month: 'short', day: 'numeric',hour:"2-digit",minute:"2-digit",second:"2-digit" };
            return date.toLocaleDateString("en-IN",dateOptions);
        }else {
            return "";
        }
        
    }
}