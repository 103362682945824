
import './App.css';
import { Route,Routes } from 'react-router-dom';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashoard';
import TicketForm from './pages/ticket/ticket-form';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/home" element={<Dashboard/>}/>
        <Route path="/ticket" element={<TicketForm/>}/>

      </Routes>
    </div>
  );
}

export default App;
