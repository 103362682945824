import { Card } from 'primereact/card';
import { Component } from 'react';
import { TicketModel } from '../../models/ticket';
import './ticket-card.component.scss'
import { Chip } from 'primereact/chip';
import { Avatar } from 'primereact/avatar';

export class TicketCard extends Component<TicketModel, { func: any }> {


    dragStart = (ev: any) => {
        ev.dataTransfer.setData("ticket", JSON.stringify({ id: this.props.id, status: this.props.status }));
        console.log("Drag Started");
    }

    getPriority() {
        switch (this.props.priority) {
            case 'TRIVIAL':
                return 'priority-trivial';
            case 'MINOR':
                return 'priority-minor';
            case 'LOW':
                return 'priority-low';
            case 'MEDIUM':
                return 'priority-medium';
            case 'MAJOR':
                return 'priority-major';
            case 'CRITICAL':
                return 'priority-critical';
            case 'BLOCKER':
                return 'priority-blocker';
        }
    }

    render() {
        const header = (<div className='tic-card-header'><span className='ticket-id'>{this.props.ticketId}</span><span>{this.props.heading}</span></div>);
        const footer = (<div className='tic-card-footer'><Chip label={this.props.assignedUserName} icon="pi pi-user" className="mr-2 mb-2" /></div>);
        let cUsuffixes = this.props.createdUserName?.match(/\b(\w)/g);
        const cUsuffix = cUsuffixes?.join()?.replace(',', '');
        return (<Card className={'tic-card '+this.getPriority()} draggable="true" onDragStart={(event) => this.dragStart(event)} header={header}
            footer={footer}
            key={this.props.id.toString()} id={this.props.id.toString()}>
            <div><Avatar className='cu-avatar' label={cUsuffix} /> <span>{this.props.createdUserName}</span></div>
        </Card>)
    }

}