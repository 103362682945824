import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Card } from "primereact/card";
import './dashboard.scss'
import { TicketModel } from "../../models/ticket";
import { Toolbar } from 'primereact/toolbar';
import { Button } from "primereact/button";
import { InputTextarea } from 'primereact/inputtextarea';
import LocalStorageService from "../../services/localstorage.service";
import { useNavigate } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import Ticket from "../ticket/ticket";
import { TicketCard } from "../../components/ticket-card/ticket-card.component";
import './../ticket/ticket-form.scss';
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from "primereact/progressbar";
import CommentModel from "../../models/comment";
import HttpService from "../../services/http.service";
import { Dropdown } from "primereact/dropdown";
import DateUtilService from "../../services/date.service";
import { BlobServiceClient, newPipeline, AnonymousCredential } from '@azure/storage-blob';
import { ProgressSpinner } from "primereact/progressspinner";
import TicketAttachmentsModel from "../../models/ticketAttachments";

interface DashboardProps { };

const Dashboard: FC<DashboardProps> = (props) => {
    const [inProgressList, setInProgressList] = useState<TicketModel[]>([]);
    const [todoList, setTodoList] = useState<TicketModel[]>([])
    const [resolvedList, setResolvedList] = useState<TicketModel[]>([])
    const [completedList, setCompletedList] = useState<TicketModel[]>([])
    const [ticketList, setTicketList] = useState<TicketModel[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<TicketModel>();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [newCommentAttachments, setNewCommentAttachments] = useState<File[]>([]);
    const [newComment, setNewComment] = useState('');
    const [progressBarValues, setProgressBarValues] = useState<number[]>([]);
    const [comments, setComments] = useState<CommentModel[]>([]);
    const [editPrirority, setEditPriority] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [editassigne, setEditAssigne] = useState(false);
    const [userList, setUserList] = useState<[]>([]);
    const [accountName, setAccountName] = useState('');
    const [containerName, setNewContainerName] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [attachfiles, setAttachfiles] = useState<File[]>([]);
    const [pipeline, setPipeline] = useState();
    const [showProgress, setShowProgress] = useState(false);
    const [attachmentList, setattachmentList] = useState<TicketAttachmentsModel[]>([]);
    const [attachmentVisible, setattachmentVisible] = useState(false);

    let uploadCount=0
    let customPipeline: any;
    const navigate = useNavigate();

    const fileRef = useRef<FileUpload>(null);

   


    const priorities = [
        { name: 'Trivial', value: 'TRIVIAL', img: 'trivial.png' },
        { name: 'Minor', value: 'MINOR', img: 'minor.png' },
        { name: 'Low', value: 'LOW', img: 'low.png' },
        { name: 'Medium', value: 'MEDIUM', img: 'medium.png' },
        { name: 'Major', value: 'MAJOR', img: 'major.png' },
        { name: 'Critical', value: 'CRITICAL', img: 'critical.png' },
        { name: 'Blocker', value: 'BLOCKER', img: 'blocker.png' }
    ];

    const statuses = [
        { name: 'To-Do', value: 'TODO', img: 'trivial.png' },
        { name: 'In-Progress', value: 'INPROGRESS', img: 'minor.png' },
        { name: 'Review', value: 'REVIEW', img: 'low.png' },
        { name: 'Resolved', value: 'RESOLVED', img: 'medium.png' },
        { name: 'Completed', value: 'COMPLETED', img: 'major.png' },
        { name: 'Archieved', value: 'ARCHIEVED', img: 'critical.png' }
    ];

    const handleLogout = () => {
        LocalStorageService.clear()
        navigate("/");
    }

    const checkAccess = useCallback(() => {
        if (!LocalStorageService.getAccessToken()) {
            LocalStorageService.clear();
            navigate("/");
        }
    },[]);

    const postComment = (files: any) => {
        setShowProgress(true);
        let commentLists = [];
        if(attachfiles.length != 0){
            processUploadData(attachfiles);
        }
        else {
            if(newComment.length != 0){
                let commentData = {
                    comment: newComment,
                    ticketId: selectedTicket?.id,
                    type: 'TEXT'
                };
                commentLists.push(commentData);
                HttpService.post(commentLists,'/comment').then((response)=>{
                    setNewComment('');
                    initComments(selectedTicket?.id!);
                
                }).catch((error)=>{
                    // this.setState({showProgress:false});
                    console.log(error);
                }).then((res) => {
                    //update state finally
                    setShowProgress(false);
                });
            }
        }
    }

    const processUploadData = (files: any) => {
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${accessToken}`, pipeline);
        const containerClient = blobServiceClient?.getContainerClient(containerName);
        const fileCommentList =[];
        for (let i = 0; i < files.length; i++) {
            let filetype = files[i].name.split('.');
            let fileName = filetype[0] + '-' + Math.floor(Date.now() / 1000) + '.' + filetype[1];
            fileCommentList.push(fileName.toString());
            uploadingFiles(files[i], containerClient, fileName, i, fileCommentList);
          }

    }

    const uploadingFiles = async (file: any, containerClient: any, path: any, index: any, fileCommentLists: any) => {
        const blockBlobClient = containerClient.getBlockBlobClient(path);
        const response = await blockBlobClient.upload(file, file.size, {
            onProgress: (ev: any) => progressReport(ev.loadedBytes, file, index), onError: (er:any) => {console.log(er); setShowProgress(false);},
            blobHTTPHeaders: {blobContentType: path.type}
        });
        
        if (response._response.status == 201) {
            uploadCount++;
            if (uploadCount == attachfiles.length) {
                let CommentList: any[] = [];
                for (let i = 0; i < fileCommentLists.length; i++) {
                    let commentData = {
                        comment: fileCommentLists[i],
                        ticketId: selectedTicket?.id,
                        type: 'FILE'
                    };
                    CommentList.push(commentData);
                }
                HttpService.post(CommentList,'/comment').then((response)=>{
                    removeFiles();
                    initComments(selectedTicket?.id!);
                    setAttachfiles([]);

                }).catch((error)=>{
                    console.log(error);
                }).then((res) => {
                    //update state finally
                    setShowProgress(false);
                });
            }
        }

    }

    const progressReport = (progress: any, file: any, index: any) => {
        let percentProgress = (progress / file.size) * 100;
        let values =[];
        values[index] = Math.trunc(percentProgress);
        setProgressBarValues(values);
      }


    const leftContents = (
        <Fragment>
            <p>Md Support</p>
        </Fragment>
    );

    const rightContents = (
        <Fragment>
            {/* <Button label="New" icon="pi pi-plus" className="mr-5" onClick={() => setDialogVisible(true)} /> */}
            <Button icon="pi pi-power-off" className="p-button-danger" onClick={handleLogout} />
        </Fragment>
    );
    const allowDrop = (event: any) => {
        event.preventDefault();
    }

    const initUsers = () => {
        setShowProgress(true);
        HttpService.get('/user').then((res: any) => {
            if (res.data.response) {
                setUserList(res.data.response.users)
            }
        }).catch((error) => {
            console.log(error);
        }).then((res) => {
            //update state finally
            setShowProgress(false);
        })
    }

    const dropped = (event: any, container: string) => {
        let tempTicket = JSON.parse(event.dataTransfer.getData("ticket"));
        let ticketId = ticketList.filter((ticket) => ticket.id == tempTicket.id)[0].ticketId;
        let ticket: TicketModel = { id: tempTicket.id, ticketId: ticketId };
        switch (container) {

            case ("todo"):
                ticket.status = "TODO"
                break;
            case ("in-progress"):
                ticket.status = "INPROGRESS"
                break;
            case ("resolved"):
                ticket.status = "RESOLVED"
                break;
            case ("completed"):
                ticket.status = "COMPLETED"
                break;

        }
        event.preventDefault();
        updateTicket(ticket, 'true');
    }

    const selectTicket = (item: TicketModel) => {
        setSelectedTicket(item);
        initComments(item.id);
        azureBlobServiceInitialization();
        fetchingAttachments(item.id);
    }

    const customBase64Uploader = () => {

    }

    // call azure storage service
    const azureBlobServiceInitialization = () => {
        setShowProgress(true);
        customPipeline = newPipeline(new AnonymousCredential(), {
            retryOptions: { maxTries: 4 }, // Retry options
            userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
            keepAliveOptions: {
                // Keep alive is enabled by default, disable keep alive by setting false
                enable: false
            }
        });
        setPipeline(customPipeline);
        HttpService.get('/sasToken').then((res: any) => {
            if (res.data.response) {
                setAccountName(res.data.response.accountName);
                setNewContainerName(res.data.response.containerName);
                setAccessToken(res.data.response.accessToken);
            }
        }).catch((error) => {
            console.log(error);
        }).then((res) => {
            //update state finally
            setShowProgress(false);
        });
    }

    // fetching ticket attachments
    const fetchingAttachments = (ticketId: any) => {
        setShowProgress(true);
        HttpService.get('/attachments?ticketId=' + ticketId).then((res: any) => {
            if (res.data.response) {
                let listOfAttachment = res.data.response.ticketAttachment;
                setattachmentList(listOfAttachment);
            }
        }).catch((error) => {
            console.log(error);
        }).then((res) => {
            //update state finally
            setShowProgress(false);
        })
    }

    const fileSelect = (event: any) => {
        let values: number[] = [];
        let files: File[] = [];
        for (let i = 0; i < event.files.length; i++) {
            values.push(0);
            files.push(event.files[i]);
        }
        setProgressBarValues(values);
        setNewCommentAttachments(files);
        setAttachfiles(files);
    }

    const removeFiles = () => {
        fileRef.current?.clear();
        setNewCommentAttachments([]);
    }

    const removeFile = (item: any, index: number) => {
        let values: number[] = [];
        let fileList = newCommentAttachments;
        fileList.splice(index, 1);
        for (let i = 0; i < fileList.length; i++) {
            values.push(0);
        }
        setProgressBarValues(values);
        setNewCommentAttachments(fileList);
    }

    // download attachment functionality
    const downloadAttachment = async (fileToDownload: any) => {
        setShowProgress(true);
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${accessToken}`, pipeline);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(fileToDownload);
        const downloadBlockBlobResponse = await blobClient.download();

        saveToFileSystem(await downloadBlockBlobResponse.blobBody, 'application/zip', fileToDownload);
    }

    // save file in the system
    const saveToFileSystem = (response: any, type: any, blobName: any) => {
        setShowProgress(false);
        let filename = blobName.replace(/\"/gi, '');
        const blob = new Blob([response]);
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
      }

    const chatFooter = () => {
        let chooseOptions = {
            icon: "pi pi-paperclip",
            iconOnly: true,
            className: "attachment-button"
        };

        return (<div className="chat-inp-container">
            <div className="chat-input">{newCommentAttachments.length === 0 && <InputText value={newComment} onChange={(event) => setNewComment(event.target.value)} />}
                {newCommentAttachments.length > 0 && <div className="selected-files">{newCommentAttachments.map((item, index) => { return itemTemplate(item, index) })}</div>}
                {newCommentAttachments.length === 0 ? <FileUpload onSelect={fileSelect} ref={fileRef} chooseOptions={chooseOptions}
                    headerTemplate={headerTemplate} contentClassName="file-content" name="attachments[]" multiple accept="image/*" customUpload uploadHandler={customBase64Uploader} />
                    : <Button onClick={removeFiles} className="p-button-rounded p-button-outlined " icon="pi pi-times" />}
            </div><Button className="post-button p-button-raised p-button-success" icon="pi pi-send"  onClick={() => postComment(fileRef)} />
        </div>)
    };

    const headerTemplate = (options: any) => {
        const { chooseButton } = options;
        return (<> {chooseButton}</>)
    }

    const itemTemplate = (item: any, index: number) => {
        return (<div className="upload-item selected-file">  <div className="flex align-items-center"><i className="pi pi-image mr-2"></i></div>
            <div className="file-name">{item.name}</div><ProgressBar value={progressBarValues[index]} className="custom-progress-bar"></ProgressBar>
            <Button type="button" icon="pi pi-times"
                className=" remove-btn p-button-rounded p-button-danger" onClick={() => removeFile(item, index)}></Button></div>)
    }

    const initTickets = useCallback(() => {
        setShowProgress(true);
        HttpService.get('/ticket').then((res: any) => {
            if (res.data.response) {
                let tickets: TicketModel[] = res.data.response.tickets.map((ticket: any) => {
                    return {
                        heading: ticket.summary,
                        priority: ticket.priority,
                        status: ticket.status,
                        id: ticket.id,
                        assignedUserName: ticket.assignedUser?.displayName,
                        createdUserName: ticket.user?.displayName,
                        ticketId: ticket.ticketId,
                        createdOn: DateUtilService.getLocalDateFromUtcMilli(ticket.createdOn),
                        updatedOn: DateUtilService.getLocalDateFromUtcMilli(ticket.updatedOn),
                        resolvedOn: DateUtilService.getLocalDateFromUtcMilli(ticket.resolvedOn),
                        description: ticket.description,
                        info: ticket.info
                    }
                });
                setTicketList(tickets);
                setInProgressList(tickets.filter((ticket: any) => ticket.status === "INPROGRESS"));
                setTodoList(tickets.filter((ticket: any) => ticket.status === "TODO"));
                setResolvedList(tickets.filter((ticket: any) => ticket.status === "RESOLVED"));
                setCompletedList(tickets.filter((ticket: any) => ticket.status === "COMPLETED"));
                if (selectedTicket) {
                    setSelectedTicket(tickets.filter((ticket: any) => ticket.id === selectedTicket.id)[0]);
                }
            }
        }).catch((error) => {
            console.log(error);
        }).then((res) => {
            //update state finally
            setShowProgress(false);
        })

    },[]);

    const options = (option: any) => {
        return (
            <div className="priority-option">
                <img alt={option.name} src={'priority/' + option.img} />
                <div>{option.name}</div>
            </div>
        );
    }

    const updatePriority = (e: any) => {
        let ticket: TicketModel = { id: selectedTicket?.id! };
        ticket.priority = e.target.value;
        updateTicket(ticket, 'false');
        setEditPriority(false);
    }

    const updateStatus = (e: any) => {
        let ticket: TicketModel = { id: selectedTicket?.id! };
        ticket.status = e.target.value;
        ticket.ticketId = selectedTicket?.id;
        updateTicket(ticket, 'false');
        setEditStatus(false);
    }
    const updateAssigne = (e: any) => {
        let ticket: TicketModel = { id: selectedTicket?.id! };
        ticket.assigne = e.target.value?.id;
        updateTicket(ticket, 'false');
        setEditAssigne(false);
    }

    const updateTicket = (ticket: TicketModel, assginUser: string) => {
        setShowProgress(true);
        HttpService.patch(ticket, '/ticket?deafaultAssign=' + assginUser).then((res: any) => {
            initTickets();
        }).catch((error) => {

        }).then((res) => {
            //update state finally
            setShowProgress(false);
        })
    }

    const initComments = (ticketId:string) => {
        setShowProgress(true);
        let comments: CommentModel[] = [];
        HttpService.get(`/ticket/${ticketId}/comment`).then((res: any) => {
            let commentsResponse = res.data.response.comments;
                if(commentsResponse.length != 0){
                    for (let i = 0; i < commentsResponse.length; i++) {
                    comments.push({
                        comment: commentsResponse[i].comment,
                        commentType: commentsResponse[i].commentType,
                        commentedOn: (new Date(commentsResponse[i].createdOn)).toDateString(),
                        commentedBy: commentsResponse[i].createdUser?.displayName,
                        commentedById: commentsResponse[i].createdUser?.id,
                    });
                    comments.sort((a, b) => (Number.parseInt(b.commentedOn) - Number.parseInt(a.commentedOn)))
                    setComments(comments);
                    setShowProgress(false);
                }
            }
            else{
                setComments(comments);
                setShowProgress(false);
            }
        }).catch((error) => {

        }).then((res) => {
            //update state finally
        });
    }

    const getAzureAccessToken = ()=>{
        HttpService.get(`storage-token`).then((res: any) => {
            comments.sort((a, b) => (Number.parseInt(b.commentedOn) - Number.parseInt(a.commentedOn)))
            setComments(comments);
        }).catch((error) => {

        }).then((res) => {
            //update state finally
        })
    }

    const commentTemplate = (comment: CommentModel) => {
        const current_userId = LocalStorageService.getUserId();
        const prefix = <><div>{comment.commentedBy}</div><div>{comment.commentedOn}</div></>
        return (<Card header={prefix} className={comment.commentedById === current_userId ? 'comment float-left' : 'comment float-right'}>
            {comment.commentType === 'FILE' && <div><i className="pi pi-file"></i><div className="file-link" onClick={(e) => downloadAttachment(comment.comment)}>{comment.comment} </div></div>}
            {comment.commentType === 'TEXT' && <div><div>{comment.comment}</div></div>}
        </Card>)
    }

    useEffect(() => {
        checkAccess();
        initTickets();
        initUsers();
    }, [initTickets,checkAccess]);

    return (<div className="dashboard-container">
        {showProgress && <div className="progress-container"><ProgressSpinner strokeWidth="5" fill="transparent" animationDuration="1s"/></div>}
        <Toolbar left={leftContents} right={rightContents} />
        <Dialog header="Header" visible={dialogVisible} style={{ width: '50vw' }} onHide={() => setDialogVisible(false)}>
            <Ticket />
        </Dialog>
        <div className="dashboard-body">
            <Card className="tickets-container">
                <Splitter style={{ height: 'calc(100vh - 10rem)' }} layout='horizontal'>
                    <SplitterPanel size={50} >
                        <Splitter layout='horizontal' >
                            <SplitterPanel size={25}>
                                <div>To-do</div>
                                <div className="tic-list-container" onDragOver={(event) => allowDrop(event)} onDrop={(event) => dropped(event, "todo")}>
                                    {todoList.map((item: TicketModel) => <div onClick={() => selectTicket(item)}><TicketCard  {...item} /></div>)}
                                </div>
                            </SplitterPanel>
                            <SplitterPanel size={25}>
                                <div>In Progress</div>
                                <div className="tic-list-container" onDragOver={(event) => allowDrop(event)} onDrop={(event) => dropped(event, "in-progress")}>
                                    {inProgressList.map((item: TicketModel) => <div onClick={() => selectTicket(item)}><TicketCard {...item} /></div>)}
                                </div>
                            </SplitterPanel>
                        </Splitter>
                    </SplitterPanel>
                    <SplitterPanel size={50}>
                        <Splitter layout='horizontal' style={{ height: '100%' }}>
                            <SplitterPanel size={25}>
                                <div>Resolved</div>
                                <div className="tic-list-container" onDragOver={(event) => allowDrop(event)} onDrop={(event) => dropped(event, "resolved")}>
                                    {resolvedList.map((item: TicketModel) => <div onClick={() => selectTicket(item)}><TicketCard {...item} /></div>)}
                                </div>
                            </SplitterPanel>
                            <SplitterPanel size={25}>
                                <div>Completed</div>
                                <div className="tic-list-container" onDragOver={(event) => allowDrop(event)} onDrop={(event) => dropped(event, "completed")}>
                                    {completedList.map((item: TicketModel) => <div onClick={() => selectTicket(item)}> <TicketCard  {...item} /></div>)}
                                </div>
                            </SplitterPanel>
                        </Splitter>
                    </SplitterPanel>
                </Splitter>
            </Card>
            <div className="ticket-details-container">
                {selectedTicket && <div>
                    <Card className='tic-details-header' header={selectedTicket.ticketId}>
                        {selectedTicket.heading}
                    </Card>
                    <Card className='tic-details' header='Details'>
                        <ul>
                            <li><div className="wrap"><span className="name">Status </span>{!editStatus && <span className="value">{selectedTicket.status}
                                <i onClick={() => setEditStatus(true)} className="action-icon pi pi-pencil" /></span>}{editStatus && <span className="value">
                                    <Dropdown name="status" value={selectedTicket.status} onChange={(e) => updateStatus(e)} options={statuses} optionLabel="name"
                                        placeholder="Select status" />
                                    <i onClick={() => setEditStatus(false)} className="edit-action-icon pi pi-times" /></span>}</div></li>
                            <li><div className="wrap"><span className="name">Priority </span>{!editPrirority && <span className="value">{selectedTicket.priority}
                                <i onClick={() => setEditPriority(true)} className="action-icon pi pi-pencil" /></span>}{editPrirority &&
                                    <span className="value"><Dropdown name="priority" value={selectedTicket.priority} onChange={(e) => updatePriority(e)} options={priorities} optionLabel="name"
                                        itemTemplate={options}
                                        placeholder="Select priority" />
                                        <i onClick={() => setEditPriority(false)} className="edit-action-icon pi pi-times" /></span>}</div></li>
                                <li><div className="wrap"><span className="name">Info </span> <span className="value">{selectedTicket.info}</span></div></li>
                        </ul>
                    </Card> <Card className='tic-people' header='People'>
                        <ul>
                            <li><div className="wrap"><span className="name">Reporter </span><span className="value">{selectedTicket.createdUserName}</span></div></li>
                            <li><div className="wrap"><span className="name">Assigne </span>{!editassigne && <span className="value">{selectedTicket.assignedUserName}
                                <i onClick={() => setEditAssigne(true)} className="action-icon pi pi-pencil" /></span>}{editassigne && <span className="value">
                                    <Dropdown name="priority" value={selectedTicket.priority} onChange={(e) => updateAssigne(e)} options={userList}
                                        optionLabel="name" placeholder="Select User" />
                                    <i onClick={() => setEditAssigne(false)} className="action-icon pi pi-times" /></span>}</div></li>
                        </ul>
                    </Card> <Card className='tic-dates' header='Dates'>
                        <ul>
                            <li><div className="wrap"><span className="name">Created On </span><span className="value">{selectedTicket.createdOn}</span></div></li>
                            <li><div className="wrap"><span className="name">Updated On </span><span className="value">{selectedTicket.updatedOn}</span></div></li>
                            <li><div className="wrap"><span className="name">Resolved On </span><span className="value">{selectedTicket.resolvedOn}</span></div></li>
                        </ul>
                    </Card>
                    <Card className='tic-description' header='Description'>
                        <InputTextarea rows={4} className="description" disabled value={selectedTicket.description} ></InputTextarea>
                    </Card>
                    <Card className='tic-attachment' header='Attachments'>
                    <div className="navigation">
                        <a>
                        <ul className="file-link">
                        {attachmentList.length > 0 && attachmentList.map((attachment) => { return <li onClick={(e) => downloadAttachment(attachment.systemName)}> {attachment.systemName}</li>})}
                        </ul></a>
                    </div>
                    </Card>
                    <Card className='tic-comments' header='Comments' footer={chatFooter}>
                        <div className="chat-container">{comments.length > 0 && comments.map((comment) => { return commentTemplate(comment) })} </div>
                    </Card></div>}
            </div>
        </div>
    </div>)
}

export default Dashboard;
