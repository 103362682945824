
import { Component } from "react";
import './ticket-form.scss';
import Ticket from "./ticket";

export default class TicketForm extends Component {

    render() {
        return (<div className="ticket-form-container">
            <Ticket />
        </div>)
    }
}