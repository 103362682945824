import { FC, useEffect, useState } from "react";
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import CryptoJS from 'crypto-js';
import './login.scss';
import {  P_KEY } from "../../models/constants";
import HttpService from "../../services/http.service";
import LocalStorageService from "../../services/localstorage.service";
import { useNavigate } from "react-router-dom";

interface LoginProps { };

const Login: FC<LoginProps> = (props) => {
    const [userName, setUserName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        LocalStorageService.clear();
    }, [])

    const login = (user: any) => {
        let userData = JSON.stringify(user);
        HttpService.post(userData, '/login').then((res: any) => {
            validateUser(res.data.response);
        }).catch((error) => {

        }).then((res) => {
            //update state finally
        })
    }

    const validateUser = (res: any) => {
        if (res && res.accessInfo) {
            LocalStorageService.saveAccessToken(res.accessInfo);
            LocalStorageService.saveUserName(res.name);
            LocalStorageService.saveUserId(res.id);
            navigate('/home')
        }
    }

    const [password, setPassword] = useState("");
    const handleLogin = () => {
        let user = {
            email: userName,
            password: CryptoJS.AES.encrypt(password, P_KEY).toString(),
        }
        login(user);
    }

    return (<div className="login-form-container">
        <Card className="login-card">
            <div className="grid p-fluid login-field-container">
                <div className="col-12 md:col-4 login-field">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Username" />
                    </div>
                </div>
                <div className="col-12 md:col-4  login-field">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-lock"></i>
                        </span>
                        <Password inputId="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    </div>
                </div>
                <div className="button-field">
                    <Button label="Login->" onClick={handleLogin} />
                </div>
                <span className="clear"></span>
            </div>
        </Card>
    </div>)
}

export default Login;
