import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { Dropdown } from 'primereact/dropdown'
import './ticket-form.scss';
import { Button } from "primereact/button";
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { TicketFormModel } from "../../models/ticketForm";
import { ProgressSpinner } from 'primereact/progressspinner';
import HttpService from "../../services/http.service";
const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);

const BASE_URL=process.env.NODE_ENV === 'production' ? 'https://md-support.azurewebsites.net' : process.env.REACT_APP_BASE_URL;
export default class Ticket extends Component<{}, TicketFormModel> {

    priorities = [
        { name: 'Trivial', value: 'TRIVIAL', img: 'trivial.png' },
        { name: 'Minor', value: 'MINOR', img: 'minor.png' },
        { name: 'Low', value: 'LOW', img: 'low.png' },
        { name: 'Medium', value: 'MEDIUM', img: 'medium.png' },
        { name: 'Major', value: 'MAJOR', img: 'major.png' },
        { name: 'Critical', value: 'CRITICAL', img: 'critical.png' },
        { name: 'Blocker', value: 'BLOCKER', img: 'blocker.png' }
    ];
    constructor(props: any) {
        super(props);

        this.state = {
            noOfAttachments: 0,
            description: '',
            summary: '',
            email: '',
            priority: '',
            validForm: false,
            errors: [],
            showProgress:false,
        };

        this.onTemplateSelect = this.onTemplateSelect.bind(this);
        this.onTemplateRemove = this.onTemplateRemove.bind(this);
        this.onTemplateClear = this.onTemplateClear.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.emptyTemplate = this.emptyTemplate.bind(this);
    }


    handleChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        const name = event.target.name;
        switch (name) {
            case 'description':
                this.setState({ description: value });
                break;
            case 'email':
                this.setState({ email: value });
                break;
            case 'summary':
                this.setState({ summary: value });
                break;
            case 'priority':
                this.setState({ priority: value });
                break;
            default:
                break;
        }

    }

    validateForm = () => {
        let errors: any = [];
        if (this.state.description.length < 20) { errors.push("Descriptions should have minimum of 20 characters") };
        if (this.state.summary.length < 10) { errors.push("Summary should have minimum of 10 characters") };
        if (!validEmailRegex.test(this.state.email)) { errors.push("Please enter a valid email") };
        if (this.state.priority.length < 2) { errors.push("Please select priority") };
        if (this.state.noOfAttachments > 25000000) {errors.push("Total files size should not exceed 25 MB")};
        this.setState({ errors: errors })
        return errors.length > 0 ? false : true;
    };



    fileUploadRef: any;
    toast: any;
    onTemplateSelect(e: any) {
        let totalSize = 0;
        for (let i = 0; i < e.files.length; i++) {
            totalSize += e.files[i].size;
        }

        if (this.state.noOfAttachments + totalSize > 25000000) {
            this.toast.show({ severity: 'warning', summary: 'Upload Limit', detail: 'Total file size exceeded 25 MB' });
        }

        this.setState((prevState) => ({
            noOfAttachments: prevState.noOfAttachments + totalSize
        }));
    }


    onTemplateRemove(file: any, callback: any) {
        this.setState((prevState: any) => ({
            noOfAttachments: prevState.noOfAttachments - file.size
        }), callback);
    }

    onTemplateClear() {
        this.setState({ noOfAttachments: 0 });
    }

    headerTemplate(options: any) {
        const { chooseButton, uploadButton, cancelButton } = options;
        const value = this.state.noOfAttachments / 250000;
        const formatedValue = this.fileUploadRef ? this.fileUploadRef.formatSize(this.state.noOfAttachments) : '0 B';

        return (
            <div className='upload-header-container'>
                <div className="upload-option-holder">
                    {chooseButton}
                    {uploadButton}
                    {cancelButton}

                </div>
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 25 MB`} ></ProgressBar>
            </div>
        );
    }

    itemTemplate(file: any, props: any) {
        return (
            <div className="file-item-template-holder">
                <div className="file-item-template">
                    <i role="presentation" className="pi pi-file" />
                    <div className="file-name ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </div>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => this.onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    emptyTemplate() {
        return (
            <div className="empty-file-holder">
                <i className="pi pi-file mt-3 p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Files Here</span>
            </div>
        )
    }

    selectedPriority(option: any, props: any) {
        if (option) {
            return (
                <div className="selected-priority">
                    <img alt={option.name} src={'priority/' + option.img} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    options(option: any,) {
        return (
            <div className="priority-option">
                <img alt={option.name} src={'priority/' + option.img} />
                <div>{option.name}</div>
            </div>
        );
    }

    submitForm(files: any) {
        if (!this.validateForm()) {
            this.setState({ validForm: true })
            return;
        }
        this.setState({showProgress:true});
        let form = new FormData();
        let fileList = files.getFiles();
        let ticketData: any = {
            'description': this.state.description,
            'email': this.state.email,
            'priority': this.state.priority,
            'summary': this.state.summary
        }
        for (let i = 0; i < fileList.length; i++) {
            form.append('files', fileList[i], fileList[i].fileName);
        }

        form.append('ticket', new Blob([JSON.stringify(ticketData)], {
            type: "application/json",
        }));


        fetch(BASE_URL + '/ticket-ext', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: form // body data type must match "Content-Type" header
        }).then((response)=>{
            this.setState({showProgress:false});
        }).catch((error)=>{
            this.setState({showProgress:false});
            console.log(error)
        });
    }

    render() {
        const footer = (<>{!this.state.validForm && <div />}{this.state.validForm && <div>{this.state.errors.map(error => <li>{error}</li>)}</div>}    <div><Button label="Create" aria-label="Create" onClick={() => this.submitForm(this.fileUploadRef)} />
            <Button label="Cancel" className="p-button-link" onClick={() => window.location.reload()} /></div></>)
        const chooseOptions = { icon: 'pi pi-fw pi-paperclip', iconOnly: true, className: ' p-button-rounded p-button-outlined' };
        const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload p-button-success p-button-rounded p-button-outlined' };
        const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: ' p-button-danger p-button-rounded p-button-outlined' };


        return (<>
            {this.state.showProgress && <div className="progress-container"><ProgressSpinner strokeWidth="5" fill="transparent" animationDuration="1s"/></div>}
            <Toast ref={(el) => { this.toast = el; }}></Toast>
            <Card className="ticket-holder" header='Create Ticket' footer={footer}>
                <span className="mandatory-msg"> All fields marked with astreisk(*) are required</span>
                <ul>
                    <li><div className="wrap"><span className="astreisk">*</span><span className="name">Summary </span><span className="value"><InputText name="summary" value={this.state.summary} onChange={(e) => this.handleChange(e)} /></span></div></li>
                    <li><div className="wrap"><span className="astreisk">*</span><span className="name">Description </span><span className="value"><InputTextarea name="description" value={this.state.description} onChange={(e) => this.handleChange(e)} rows={6} /></span></div></li>
                    <li><div className="wrap"><span className="astreisk">*</span><span className="name">Email </span><span className="value"><InputText name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} /></span></div></li>
                    <li><div className="wrap"><span className="astreisk">*</span><span className="name">Priority </span><span className="value"><Dropdown name="priority" value={this.state.priority} onChange={(e) => this.handleChange(e)} options={this.priorities} optionLabel="name"
                        valueTemplate={this.selectedPriority} itemTemplate={this.options}
                        placeholder="Select priority" /></span></div></li>
                    <li><div className="wrap"><span className="name">Attachments </span><span className="value">
                        <FileUpload ref={(el) => this.fileUploadRef = el} name="demo[]" multiple accept=".jpg,.png,.pdf,.jpeg,.doc,.txt,.docx" maxFileSize={25000000}
                            headerTemplate={this.headerTemplate} itemTemplate={this.itemTemplate} emptyTemplate={this.emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} progressBarTemplate={<div className="no-progress-bar"></div>}
                            onClear={this.onTemplateClear} onSelect={this.onTemplateSelect} /></span></div></li>
                </ul>

            </Card>
        </>)
    }
}